import React from "react";
import "./footer.css";

import scrollToTop from "../../functions/scrollToTop";
import { Link } from "react-router-dom";
import Logo from "../header/animatedLogo/logo";

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="footer-content-container">
          <div className="footer-logo-container">
            <Logo />
          </div>

          <br></br>
          <div className="footer-list-container">
            <ul>
              <span>Quick Links</span>

              <li>
                <Link
                  to="/"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Home Page
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/privacypolicy"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Privacy Policy
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/faq"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  FAQ
                </Link>
              </li> */}
            </ul>
            <br></br>
            <ul>
              <span>Our Services</span>
              <li>
                <Link
                  to="/services/residential"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Residential
                </Link>
              </li>
              <li>
                <Link
                  to="/services/commercial"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Commercial
                </Link>
              </li>
              <li>
                <Link
                  to="/services/emergency"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Emergency
                </Link>
              </li>
              <li>
                <Link
                  to="/services/handyman"
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="App-link"
                >
                  Handyman & Cleaning
                </Link>
              </li>
            </ul>
            <br></br>
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
      <div className="footer-copyright-container">
        <p>©{new Date().getFullYear()} DLSFixit all rights reserved.</p>
        <br></br>
        <p>
          This website was designed and developed by{" "}
          <a
            target="_blank"
            href="https://portfolio-anthony-fdez.vercel.app/"
            rel="noreferrer"
          >
            Anthony Fernandez
          </a>
        </p>
        {/* <p>
          Just kidding this is an open source project and both backend and
          frontend source code are available{" "}
          <a
            target="_blank"
            href="https://github.com/anthony-fdez/florida-lottery-frontend"
            rel="noreferrer"
          >
            here (frontend)
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://github.com/anthony-fdez/florida-lottery"
            rel="noreferrer"
          >
            here (backend)
          </a> */}
        {/* </p> */}
      </div>
    </>
  );
};

export default Footer;
