import React, { useState } from "react";
import styles from "./contact.module.css";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SEO from "../../functions/SEO";
import Fade from "react-reveal/Fade";
import Faq from "../../components/faq/faq";

import useIsLoaded from "../../functions/hooks/useIsLoaded";

const Contact = () => {
  const isLoaded = useIsLoaded();

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className={isLoaded ? "LOADED" : "NOT-LOADED"}>
      <SEO
        title={"DLSFixit | Contact"}
        description={"Contact dlsfixit's locksmith services"}
        subject={"Contact dlsfixit"}
        screen={"contact"}
        keywords="contact, locksmith, near, me, miami, information"
      />
      <div id={styles.contact_jumbo} className="jumbo">
        <div className="jumbo_content">
          <h1>We are here to help you</h1>
          <h2>Get in touch with us today!</h2>
          <br></br>
        </div>
      </div>
      <div className={styles.container}>
        <Fade>
          <div>
            <h1>Contact Information</h1>

            <p>You can reach out to us using whichever methoth you preffer</p>

            <ul>
              <li>
                <a href="mailto:service@dlsfixit.com">service@dlsfixit.com</a>
              </li>
              <li>
                <a href="tel:+17863559579">786-355-9579</a>
              </li>
            </ul>
            <br></br>
            <h2>Our Headquarters</h2>
            <ul>
              <li>Hallandale Beach, FL</li>
            </ul>
            <br></br>
            <br></br>
          </div>
        </Fade>
        <Fade>
          <div>
            <h2>Reach out to us!</h2>
            <p>
              Complete the form below and tell us your needs and how we can help
              you, we will get back to you as soon as possible.
            </p>
            <br></br>
            <Form
              action="https://formsubmit.co/service@dlsfixit.com"
              method="POST"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <input
                type="hidden"
                name="_url"
                value="https://dlsfixit.com/contact.html"
              />

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  required
                  name="Name"
                  type="text"
                  placeholder="John Doe"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name="Email"
                  required
                  type="email"
                  placeholder="Enter email"
                />
                <Form.Text className="text-muted">
                  We will reach out to you using this email
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  name="Message"
                  required
                  type="text"
                  placeholder="How can we help you?"
                  as="textarea"
                  rows="1"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  name="Message"
                  required
                  type="text"
                  placeholder="Tell us how can we help you! Be specific about your problem to give us a better understading of your needs and be able to provide an outstanding service for you!"
                  as="textarea"
                  rows="4"
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Fade>
      </div>
      {/* <div className={styles.faq_container}>
        <Fade>
          <div className={styles.center}>
            <h2>Frequently Asked Questions</h2>
          </div>
          <br></br>
          <Faq />
          <br></br>
        </Fade>
      </div> */}
    </div>
  );
};

export default Contact;
