import React from "react";
import styles from "./residential.module.css";

import image from "../../assets/residential.webp";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SEO from "../../../../functions/SEO";
import { FiPhone } from "react-icons/fi";

const Residential = () => {
  return (
    <>
      <SEO
        title={"DLSFixit | Residential Services"}
        description={"Doors and locks services for residencies"}
        subject={"DLS Residential Services"}
        screen={"services/residential"}
        keywords="about, locksmith, near, me, miami, information, pricing"
      />
      <Fade>
        <h1>Residential</h1>
        <br></br>
        <LazyLoadImage
          alt="Front of a house"
          className={styles.image}
          src={image}
        />
        <p>
          When it comes to door/lock repairs and related services, we are second
          to none. We are guided by the principles of honesty, reliability,
          timeliness, affordability, delivering quality parts and work.
        </p>

        <p>
          If there is any problem with your door/lock, we can fix it
          immediately. You don’t have to worry about anything because our team
          has all necessary tools and equipment needed to complete the job
          quickly and efficiently.
        </p>
      </Fade>

      <br></br>
      <Fade>
        <h2>
          <FiPhone className={styles.phoneIcon} />

          <a className="underline" href="tel:+17863559579">
            786-355-9579
          </a>
        </h2>
      </Fade>
      <br></br>
      <Fade>
        <p>
          We are the best choice for your residential or commercial doors and
          locks. We provide you with quality services at affordable prices.
        </p>
      </Fade>

      <br></br>
      <Fade>
        <h2>DLS provides residential services:</h2>
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </Fade>
    </>
  );
};

export default Residential;
