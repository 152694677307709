import React, { useEffect, useState } from "react";
import styles from "./header.module.css";
import "../../globalStyles/jumbo.css";

import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import scrollToTop from "../../functions/scrollToTop";

import ServicesHeader from "./servicesHeader/servicesHeader";

// Icons
import { GiHamburgerMenu } from "react-icons/gi";
import { useGetScrollDirection } from "../../functions/hooks/useGetScrollDirection";
import Logo from "./animatedLogo/logo";

const Header = () => {
  const navigate = useNavigate();
  const scrollDirection = useGetScrollDirection();

  const [offset, setOffset] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);

  // Mobile header
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleCloseMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);

    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (scrollDirection === "down" && offset >= 100) {
      setHideHeader(true);
    } else if (scrollDirection === "up") {
      setHideHeader(false);
    }
  }, [scrollDirection, offset]);

  const mobileMenu = () => {
    return (
      <Offcanvas
        placement="top"
        show={isMobileMenuOpen}
        onHide={handleCloseMenu}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>DLSFixit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={styles.nav_mobile}>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    scrollToTop();
                    setIsMobileMenuOpen(false);
                  }}
                  className="underline"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="underline"
                  to="/services"
                  onClick={() => {
                    scrollToTop();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  onClick={() => {
                    scrollToTop();
                    setIsMobileMenuOpen(false);
                  }}
                  className="underline"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  onClick={() => {
                    scrollToTop();

                    setIsMobileMenuOpen(false);
                  }}
                  className="underline"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  };

  return (
    <div className={hideHeader ? styles.hide_header : styles.show_header}>
      <header
        className={offset <= 20 ? styles.container_big : styles.container_small}
      >
        <div
          onClick={() => {
            navigate("/");
            scrollToTop();
          }}
        >
          <Logo offset={offset} />
        </div>
        <div className={styles.nav_desktop}>
          <ul>
            <li>
              <Link
                to="/services"
                onClick={() => {
                  scrollToTop();
                }}
                className={styles.white}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => {
                  navigate("/about");
                  scrollToTop();
                }}
                className={styles.white}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={() => {
                  scrollToTop();
                }}
                className={styles.white}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <button
          onClick={() => {
            setIsMobileMenuOpen(true);
          }}
          className={styles.menu_button}
        >
          <GiHamburgerMenu size="30" />
        </button>
        {mobileMenu()}
      </header>
      <ServicesHeader />
    </div>
  );
};

export default Header;
