import React from "react";
import styles from "./home.module.css";
import "../../globalStyles/jumbo.css";

import Button from "react-bootstrap/Button";
import useIsLoaded from "../../functions/hooks/useIsLoaded";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";

// Cards
import {
  AccessControl,
  AutomaticDoors,
  OverHeadDoors,
  SmartLocks,
  MetalWoodDoors,
  LocksmithServices,
} from "./components/cards";

// Icons
import { GiDoorHandle } from "react-icons/gi";
import { MdAttachMoney } from "react-icons/md";
import { HiFastForward } from "react-icons/hi";
import { AiOutlineHeart } from "react-icons/ai";
import { MdOutlineEmergency } from "react-icons/md";
import { BsFillDoorOpenFill } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";

import Alert from "react-bootstrap/Alert";
import SEO from "../../functions/SEO";
import scrollToTop from "../../functions/scrollToTop";

const Home = () => {
  const isLoaded = useIsLoaded();
  const navigate = useNavigate();

  return (
    <div className={isLoaded ? "LOADED" : "NOT-LOADED"}>
      <SEO
        title={"DLSFixit | Home"}
        description={
          "Doors, locks and locksmith services at affordable pricing and quick assistance in Miami, Hallandale Beach and South Florida area"
        }
        subject={"dlsfixit locksmith doors locks services technician handyman"}
        screen={"/"}
        keywords="contact, locksmith, near, me, miami, information"
      />
      <div id={styles.home_jumbo} className="jumbo">
        <div className="jumbo_content">
          <h1>Providing services for doors, locks and more.</h1>
          <p>
            Our highly qualified technicians are extremely responsive and
            helpful from the first time you reach out. We take the hassle out of
            the process while delivering a high-quality end product.
          </p>
          <br></br>
          <Button onClick={() => navigate("/contact")}>Contact Us</Button>
        </div>
      </div>
      <div className={styles.container}>
        <br></br>
        <h2 className={styles.header_text}>
          Our Commercial & Residential Services
        </h2>

        <div className={styles.grid}>
          <AccessControl />
          <AutomaticDoors />
          <OverHeadDoors />
          <SmartLocks />
          <MetalWoodDoors />
          <LocksmithServices />
        </div>
        <Fade>
          <Link
            onClick={() => {
              scrollToTop();
            }}
            className={styles.services_link}
            to="/services"
          >
            <h4 className="underline">See all our services</h4>
          </Link>
          <br></br>
        </Fade>
        <Fade>
          <div>
            <br></br>
            <h2 className={styles.header_text}>
              Why Choose DLS's doors and locks technician?
            </h2>
            <br></br>

            <p>
              Licensed, bonded, and insured, our door technicians bring
              old-fashioned values to every job, giving you confidence in a job
              well done. Some door companies are interested in earning another
              dollar, not their customers' needs. We're not the usual door
              technicians and we're proud to be different. You see, we
              understand to get to the top you must do things differently, do
              them better, than the next guy. We strive to exceed expectations
              no matter your needs. There is no job to small or too large for
              our experts to handle.
            </p>
          </div>
        </Fade>
        <br></br>
      </div>
      <div className={styles.full_width_black}>
        <div className={styles.full_width_black_content}>
          {/* <Fade>
            <div className={styles.flex_center}>
              <BiLockAlt className={styles.full_width_black_icon} />
              <div>
                <h4>Let Our Professionals Handle Your Business Security</h4>
                <br></br>
                <p>
                  When you choose a locksmith for your business, you need
                  someone you can trust. After all, you are placing your
                  company's security and safety in the locksmith's hands. Our
                  professional locksmiths are licensed, bonded, and insured. You
                  can rest easy when you choose My Locksmith Miami. We are
                  active members of the Associated Locksmiths of America. We
                  understand that busy professionals need quick service at
                  reasonable prices. That's why we offer custom solutions and
                  pricing plans just for Miami's businesses. Your security is
                  our priority! Watch the video to learn more about our business
                  locksmith solutions and then call us today to schedule your
                  free estimate.
                </p>
              </div>
            </div>
          </Fade> */}
          <Fade>
            <div className={styles.container_previous_services}>
              <div>
                <GiDoorHandle className={styles.count_icon} />
                <h1>2000+</h1>
                <p>Locks Installed</p>
              </div>
              <div>
                <BsFillDoorOpenFill className={styles.count_icon} />

                <h1>250+</h1>
                <p>Doors Installed</p>
              </div>
              <div>
                <BsPeopleFill className={styles.count_icon} />
                <h1>2000+</h1>
                <p>Satisfied Customers</p>
              </div>
            </div>
          </Fade>
          <div className={styles.card}>
            <h3>
              Whether we're performing a small task or a large task, when you
              work with us, you can expect the following:
            </h3>
            <br></br>
            <Fade bottom>
              <div className={styles.swiper_container}>
                <Swiper spaceBetween={10} slidesPerView="auto">
                  <SwiperSlide className={styles.swiper_card}>
                    <div className={styles.icon_container_card}>
                      <MdAttachMoney size="50" />
                    </div>
                    <h4>Great Pricing Structure</h4>
                    <p>
                      Our pricing structure is extremely competitive in the
                      entire South Florida area. DLS strives to be the very best
                      and with that comes our extremely affordable services!
                    </p>
                  </SwiperSlide>
                  <SwiperSlide className={styles.swiper_card}>
                    <div className={styles.icon_container_card}>
                      <HiFastForward size="50" />
                    </div>
                    <h4>Fast Response</h4>
                    <p>
                      Our staff understands how important getting a service tech
                      to your location in your time of need is a absolute must!
                      When you are in need of a fast doors or locks services in
                      the South Florida area you should consider{" "}
                      <Link onClick={() => scrollToTop()} to="/contact">
                        contacting us.
                      </Link>
                    </p>
                  </SwiperSlide>
                  <SwiperSlide className={styles.swiper_card}>
                    <div className={styles.icon_container_card}>
                      <AiOutlineHeart size="50" />
                    </div>
                    <h4>Passionate</h4>
                    <p>
                      Having been in the doors industry for over 10+ years we
                      are truly passionate about the work we provide. Each and
                      every customer receives only the highest quality of work
                      possible and we are constantly looking for ways to
                      improve!
                    </p>
                  </SwiperSlide>
                  <SwiperSlide className={styles.swiper_card}>
                    <div className={styles.icon_container_card}>
                      <MdOutlineEmergency size="50" />
                    </div>
                    <h4>Emergency</h4>
                    <p>
                      Being in an emergency situation can be quite an annoyance.
                      If you have found yourself in need of an emergency for a
                      door or lock service in the South Florida area, DLS should
                      be your first choice! All of our technicians specialize in
                      relieving emergency situations.
                    </p>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Fade>
            <br></br>
            <Alert className={styles.help_alert}>
              <p>
                <strong>Need help? </strong>
                <Link
                  onClick={() => scrollToTop()}
                  className="underline"
                  to="/faq"
                >
                  See some of our most frequently asked questions (FAQ)
                </Link>
              </p>
            </Alert>
          </div>
          <br></br>

          <br></br>
          <Fade>
            <div className={styles.call_container}>
              <div>
                <h2>DLS is here to help you!</h2>
                <p>
                  Contact us for all of your doors and locks needs, for
                  Residential, Commercial, and Emergency services.
                </p>

                <Link onClick={() => scrollToTop()} to="/contact">
                  <Button className={styles.call_button}>
                    Get in touch with us now!
                  </Button>
                </Link>
              </div>
            </div>
          </Fade>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default Home;
