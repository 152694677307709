import "./App.css";
import "./globalStyles/linksHover.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// Pages
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Maintenance from "./pages/maintenance/maintenance";
import NotFound from "./pages/notFound/notFound";
import Contact from "./pages/contact/contact";
import Services from "./pages/services/services";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";

// Services pages
import Residential from "./pages/services/screens/residential/residential";
import Commercial from "./pages/services/screens/comercial/commercial";
import Emergency from "./pages/services/screens/emergency/emergency";
import Handyman from "./pages/services/screens/handyman/handyman";

// Compoents
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import FaqPage from "./pages/faq/faqPage";
import OurWork from "./pages/ourWork/ourWork";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

function App() {
  const isMaintanenceMode = false;

  const maintenanceMode = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Maintenance />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="*" element={<Maintenance />} />
        </Routes>
      </BrowserRouter>
    );
  };

  const app = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />

          <Route
            path="about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="services"
            element={
              <Layout>
                <Services />
              </Layout>
            }
          >
            <Route index element={<Commercial />} />
            <Route path="commercial" element={<Commercial />} />
            <Route path="residential" element={<Residential />} />
            <Route path="emergency" element={<Emergency />} />
            <Route path="handyman" element={<Handyman />} />
          </Route>
          <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route
            path="/ourwork"
            element={
              <Layout>
                <OurWork />
              </Layout>
            }
          />
          <Route path="maintenance" element={<Maintenance />} />
          {/* <Route
            path="faq"
            element={
              <Layout>
                <FaqPage />
              </Layout>
            }
          /> */}
          <Route
            path="privacyPolicy"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  };

  if (isMaintanenceMode) return maintenanceMode();

  return app();
}

export default App;
