import React, { useRef } from "react";
import styles from "./services.module.css";
import "../../globalStyles/jumbo.css";

import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

import useIsLoaded from "../../functions/hooks/useIsLoaded";
import SEO from "../../functions/SEO";
import { Button } from "react-bootstrap";
import scrollToTop from "../../functions/scrollToTop";

const Services = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoaded = useIsLoaded();

  const scrollRef = useRef();

  const getActiveScreen = ({ screen }) => {
    if (screen === location.pathname) {
      return styles.side_menu_active_item;
    } else return styles.side_menu_item;
  };

  const scrollToTopOfComponent = () =>
    scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const desktopSideMenu = () => {
    return (
      <div className={styles.desktop_sidemenu_container}>
        <h2>Our services include:</h2>
        <br></br>
        <ul className={styles.side_menu}>
          <li
            className={getActiveScreen({ screen: "/services/commercial" })}
            onClick={() => {
              scrollToTopOfComponent();
              navigate("commercial");
            }}
          >
            Commercial
          </li>
          <li
            className={getActiveScreen({ screen: "/services/residential" })}
            onClick={() => {
              scrollToTopOfComponent();
              navigate("residential");
            }}
          >
            Residential
          </li>
          <li
            className={getActiveScreen({ screen: "/services/emergency" })}
            onClick={() => {
              scrollToTopOfComponent();
              navigate("emergency");
            }}
          >
            Emergency
          </li>
          <li
            className={getActiveScreen({ screen: "/services/handyman" })}
            onClick={() => {
              scrollToTopOfComponent();
              navigate("handyman");
            }}
          >
            Handyman & Cleaning
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className={isLoaded ? "LOADED" : "NOT-LOADED"}>
      <SEO
        title={"DLSFixit | Services"}
        description={
          "Locksmith Services offered by dlsfixit in the Miami, Hallandale Beach, Fort Lauderdale area Florida."
        }
        subject={"Locksmith Services offered by dlsfixit"}
        screen={"services"}
        keywords={
          "services, locksmith, key, lock, miami, hallandale, beach, unlock, near, me"
        }
      />
      <div id={styles.jumbo} className="jumbo">
        <div className="jumbo_content">
          <h1>Customer satisfaction is our top priority</h1>
          <p>
            Our customers love us because we always give them honest advice
            regarding what needs to be done and why. We don’t try to sell
            unnecessary products like other contractors might. Instead, we focus
            only on repairing problems and making sure everything works properly
            again.
          </p>
          <br></br>
        </div>
      </div>
      <div ref={scrollRef} className={styles.container}>
        <div className={styles.content_container}>
          {desktopSideMenu()}

          <div className={styles.content}>
            <Outlet />
            <br></br>
            <br></br>
            <Link
              onClick={() => {
                scrollToTop();
              }}
              to="/contact"
            >
              <Button className={styles.contact_button}>
                Get in touch with us today!
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
