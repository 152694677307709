import React from "react";
import styles from "./maintenance.module.css";

import useIsLoaded from "../../functions/hooks/useIsLoaded";
import Helmet from "react-helmet";

const Maintenance = () => {
  const isLoaded = useIsLoaded();

  return (
    <div className={isLoaded ? "LOADED" : "NOT-LOADED"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DLSFixit - Maintenance</title>
        <link rel="canonical" href="https://dlsfixit.com/maintenance" />
      </Helmet>
      <article className={styles.container}>
        <h1>We&rsquo;ll be back soon!</h1>
        <br></br>
        <div>
          <p>
            Sorry for the inconvenience but we&rsquo;re performing some
            maintenance at the moment. If you need to you can always{" "}
            <a
              className="underline"
              target="_blank"
              href="mailto:service@dlsfixit.com"
              rel="noreferrer"
            >
              contact us
            </a>
            , otherwise we&rsquo;ll be back online shortly!
          </p>
          <br></br>
          <p>&mdash; DLSFixit</p>
        </div>
      </article>
    </div>
  );
};

export default Maintenance;
