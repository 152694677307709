import React from "react";
import styles from "./emergency.module.css";

import image from "../../assets/emergency.webp";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SEO from "../../../../functions/SEO";
import { FiPhone } from "react-icons/fi";

const Emergency = () => {
  return (
    <>
      <SEO
        title={"DLSFixit | Emergency Services"}
        description={
          "Emergency doors and locks services, no matter the time time, we will keep your home safe"
        }
        subject={"DLS Emergency Services"}
        screen={"services/emergency"}
        keywords="about, locksmith, near, me, miami, information, pricing"
      />

      <Fade>
        <h1>Emergency Services</h1>
        <br></br>
        <LazyLoadImage
          alt="Emergency sign"
          src={image}
          className={styles.image}
        />
        <br></br>
        <Fade>
          <h2>
            <FiPhone className={styles.phoneIcon} />

            <a className="underline" href="tel:+17863559579">
              786-355-9579
            </a>
          </h2>
        </Fade>
        <br></br>
        <p>
          Doors and locks can break at any time of day. That’s why at DLS of, we
          have someone waiting to answer your call 24/7. We will schedule your
          appointment at a time that is convenient for you. Our trucks always
          carry a full inventory of parts, which means we are prepared to repair
          your door or lock in a single visit.
        </p>
      </Fade>
    </>
  );
};

export default Emergency;
