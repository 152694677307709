import React from "react";
import styles from "./handyman.module.css";

import image from "../../assets/handyman.webp";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SEO from "../../../../functions/SEO";
import { FiPhone } from "react-icons/fi";

const Handyman = () => {
  return (
    <>
      <SEO
        title={"DLSFixit | Handyman Services"}
        description={"We provide handyman and cleaning services"}
        subject={"DLS Handyman & Cleaning Services"}
        screen={"services/handyman"}
        keywords="about, locksmith, near, me, miami, information, pricing"
      />
      <Fade>
        <h1>Handyman & Cleaning Services</h1>
        <br></br>
        <LazyLoadImage
          alt="Emergency sign"
          src={image}
          className={styles.image}
        />
        <p>
          Here at DLS we are very experienced and will be sure to meet the
          expectations of what you are looking to have done. We are properly
          skilled & very Detail-oriented for our Trade.
        </p>
      </Fade>

      <Fade>
        <h2>
          <FiPhone className={styles.phoneIcon} />

          <a className="underline" href="tel:+17863559579">
            786-355-9579
          </a>
        </h2>
      </Fade>

      <br></br>
      <Fade>
        <h2>Handyman and cleaning services DLS offers:</h2>
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </Fade>
    </>
  );
};

export default Handyman;
