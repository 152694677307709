import React from "react";
import styles from "./about.module.css";
import "../../globalStyles/jumbo.css";

import SEO from "../../functions/SEO";
import useIsLoaded from "../../functions/hooks/useIsLoaded";

import Fade from "react-reveal/Fade";

// Images
import AAADM from "./assets/AAADM.jpeg";
import CertifiedLocksmith from "./assets/certified-locksmith.jpeg";
import Nextkey from "./assets/nextkey.png";
import OpenPath from "./assets/openpath.webp";
import Vizpin from "./assets/vizpin.avif";
import Zkteco from "./assets/zkteco.png";

const About = () => {
  const isLoaded = useIsLoaded();

  return (
    <div className={isLoaded ? "LOADED" : "NOT-LOADED"}>
      <SEO
        title={"DLSFixit | About Us"}
        description={
          "Information about what motivates us, and how we are your best choice when it comes to a locksmith"
        }
        subject={"About dlsfixit"}
        screen={"about"}
        keywords="about, locksmith, near, me, miami, information, pricing"
      />
      <div id={styles.about_jumbo} className="jumbo">
        <div className="jumbo_content">
          <h1>A company you can trust!</h1>
        </div>
      </div>
      <div className={styles.about_container}>
        <Fade>
          <h1>Our Mission</h1>
          <br></br>
          <p>
            Our goal at DLS is to provide excellent customer service and
            high-quality workmanship every time. Whether it be residential or
            commercial jobs, we strive to exceed expectations every time. With
            over 10 years of experience in the industry, we understand how
            important it is to get the job done right the first time around.
            That’s why we offer free estimates and always give honest advice
            about what needs to be repaired or replaced. You’ll never feel
            pressured into making an unnecessary purchase when dealing with us
            because we want to earn your business through great communication
            and fair pricing.
          </p>
        </Fade>
        <br></br>
        <Fade>
          <h1>Our Certifications</h1>
          <div className={styles.certifications_container}>
            <a
              href="https://www.aaadm.com/"
              target="_blank"
              className={styles.image_link_container}
              rel="noreferrer"
            >
              <img
                alt="AADM"
                className={styles.certification_image}
                src={AAADM}
              />
            </a>

            <a
              href="https://www.nexkey.com/"
              rel="noreferrer"
              target="_blank"
              className={styles.image_link_container}
            >
              <img
                alt="NextKey"
                className={styles.certification_image}
                src={Nextkey}
              />
            </a>

            <a
              href="https://www.openpath.com/"
              rel="noreferrer"
              target="_blank"
              className={styles.image_link_container}
            >
              <img
                alt="OpenPath Certification"
                className={styles.certification_image}
                src={OpenPath}
              />
            </a>

            <a
              href="https://vizpin.com/certified-installer-program/"
              rel="noreferrer"
              target="_blank"
              className={styles.image_link_container}
            >
              <img
                alt="Vizpin Certification"
                className={styles.certification_image}
                src={Vizpin}
              />
            </a>

            <a
              href="https://www.zkteco.com/en/"
              rel="noreferrer"
              target="_blank"
              className={styles.image_link_container}
            >
              <img
                alt="ZKTeco certification"
                className={styles.certification_image}
                src={Zkteco}
              />
            </a>

            <a
              rel="noreferrer"
              target="_blank"
              className={styles.image_link_container}
            >
              <img
                alt="Certified locksmith"
                className={styles.certification_image}
                src={CertifiedLocksmith}
              />
            </a>
          </div>
        </Fade>
        {/* <Fade>
          <div className={styles.what_we_do}>
            <div className={styles.first}>
              <img
                alt="Door"
                className={styles.info_image}
                src={whatWeDoImage}
              />
            </div>
            <div className={styles.second}>
              <h1>What do we do?</h1>
              <br></br>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniamLorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniamLorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam
              </p>
            </div>
          </div>
        </Fade> */}
      </div>
    </div>
  );
};

export default About;
