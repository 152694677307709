import React from "react";
import styles from "./commercial.module.css";

import image from "../../assets/commercial.webp";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SEO from "../../../../functions/SEO";
import { FiPhone } from "react-icons/fi";

const Commercial = () => {
  return (
    <>
      <SEO
        title={"DLSFixit | Commercial Services"}
        description={"Doors and locks services for commercial business"}
        subject={"DLS Commercial Services"}
        screen={"services/commercial"}
        keywords="about, locksmith, near, me, miami, information, pricing"
      />
      <Fade>
        <h1>Commercial</h1>
        <br></br>
        <LazyLoadImage alt="Buildings" className={styles.image} src={image} />
        <p>
          When you need someone that knows all about how to ensure that your
          commercial personal property is secured at its top, know one thing:
          with a call to DLS, we can provide the stellar assistance you require
          at some of the finest rates around. It's crucial to reach out to DLS
          the moment you require our team to assist you out the most, so cease
          waiting around another second: reach out to us now and become educated
          about everything that we can do to assistance you the moment you
          require our assistance the most!
        </p>
      </Fade>

      <br></br>
      <Fade>
        <h2>
          <FiPhone className={styles.phoneIcon} />

          <a className="underline" href="tel:+17863559579">
            786-355-9579
          </a>
        </h2>
      </Fade>

      <br></br>
      <Fade>
        <h2>DLS offers commercial services:</h2>
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </Fade>
    </>
  );
};

export default Commercial;
