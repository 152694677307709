import React, { useState, useEffect } from "react";
import styles from "./servicesHeader.module.css";
import { useLocation, useNavigate } from "react-router-dom";

import { BsChevronDown } from "react-icons/bs";

const ServicesHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [offset, setOffset] = useState(0);

  const getActiveScreen = ({ screen }) => {
    if (screen === location.pathname) {
      return styles.side_menu_active_item;
    } else return styles.side_menu_item;
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);

    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  if (location.pathname.split("/")[1] !== "services") return null;

  return (
    <div
      style={offset < 20 ? { opacity: 0 } : {}}
      className={
        isSideMenuOpen
          ? styles.side_menu_mobile
          : styles.side_menu_mobile_closed
      }
    >
      <div
        onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
        className={styles.mobile_header}
      >
        <h5>Our Services</h5>
        <div className={isSideMenuOpen ? styles.up_arrow : styles.down_arrow}>
          <BsChevronDown />
        </div>
      </div>
      <ul className={styles.mobile_list}>
        <li
          className={getActiveScreen({ screen: "/services/commercial" })}
          onClick={() => {
            navigate("commercial");
            setIsSideMenuOpen(false);
          }}
        >
          Commercial
        </li>
        <li
          className={getActiveScreen({ screen: "/services/residential" })}
          onClick={() => {
            navigate("residential");
            setIsSideMenuOpen(false);
          }}
        >
          Residential
        </li>

        <li
          className={getActiveScreen({ screen: "/services/emergency" })}
          onClick={() => {
            navigate("emergency");
            setIsSideMenuOpen(false);
          }}
        >
          Emergency
        </li>
        <li
          className={getActiveScreen({ screen: "/services/handyman" })}
          onClick={() => {
            navigate("handyman");
            setIsSideMenuOpen(false);
          }}
        >
          Handyman & Cleaning
        </li>
      </ul>
    </div>
  );
};

export default ServicesHeader;
