import React from "react";
import styles from "./notFound.module.css";

import image from "./light-bulb.png";
import SEO from "../../functions/SEO";

const NotFound = () => {
  return (
    <div className={styles.container}>
      <SEO
        title={"DLSFixit - 404"}
        description={"Page not found"}
        subject={"404"}
        screen={"404"}
      />
      <img alt="light bulb" src={image} className={styles.image} />

      <div className={styles.dialog}>
        <h1>Hey, who turned off the lights?</h1>
        <p>We were unable to find the page you were looking for.</p>
      </div>
    </div>
  );
};

export default NotFound;
