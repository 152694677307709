import React from "react";
import Fade from "react-reveal/Fade";

import styles from "../home.module.css";

// Icons
import { FaKey } from "react-icons/fa";
import { BsFillDoorClosedFill } from "react-icons/bs";
import { BiKey } from "react-icons/bi";
import { FiLock } from "react-icons/fi";
import { MdVpnLock } from "react-icons/md";
import { GiDoorHandle } from "react-icons/gi";
import { IoMdKeypad } from "react-icons/io";
import { MdOutlineDoorSliding } from "react-icons/md";
import { GiHomeGarage } from "react-icons/gi";
import { MdScreenLockPortrait } from "react-icons/md";
import { GiKeyLock } from "react-icons/gi";

export const AccessControl = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <IoMdKeypad size="100" className={styles.icon} />
        <div>
          <h3>Access Control Systems</h3>
          <p>
            Access Control systems allow you to prevent intrusions from people
            you do not desire at your facility or place of business
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const AutomaticDoors = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <MdOutlineDoorSliding size="100" className={styles.icon} />
        <div>
          <h3>Automatic Pedestrians Doors</h3>
          <p>
            DLS is here to handle the inspection and maintenance of all of your
            automatic doors to ensure they are in peak working order.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const OverHeadDoors = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <GiHomeGarage size="100" className={styles.icon} />
        <div>
          <h3>Overhead/Garage Doors</h3>
          <p>
            We have the best team of expert professionals who know how to handle
            whatever your requirements or needs for your garage door or roll up
            door might be.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const SmartLocks = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <MdScreenLockPortrait size="100" className={styles.icon} />
        <div>
          <h3>Smart Locks</h3>
          <p>
            Smart locks are a modern, convenient, and effective solution for
            residential and business security.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const MetalWoodDoors = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <BsFillDoorClosedFill size="100" className={styles.icon} />
        <div>
          <h3>Metal & Wood Doors</h3>
          <p>
            Today's retail, office, and warehouse spaces have complex entry and
            exit systems that need to be serviced and maintained to keep your
            business operating like it should.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const LocksmithServices = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <GiKeyLock size="100" className={styles.icon} />
        <div>
          <h3>Complete Locksmith Services</h3>
          <p>
            DLS is a door and lock company that provide professional quality
            service that people trust. Our technicians not only install doors
            but offer repairs for existing doors.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const LockRekeying = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <BiKey size="100" className={styles.icon} />
        <div>
          <h3>Lock Rekeying</h3>
          <p>
            Time to change the locks? Our mobile service can professionally
            rekey your lock. Or bring the lock to us and save!
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const LockRepair = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <FiLock size="70" className={styles.icon} />
        <div>
          <h3>Lock Repair</h3>
          <p>
            Aging? Broken? We can repair almost any lock! Shop or mobile service
            available.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const LockInstallation = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <MdVpnLock size="90" className={styles.icon} />{" "}
        <div>
          <h3>Lock Installation</h3>
          <p>
            Replacing a lock? Installing a lock in a new door? Our professional
            lock installation service has you covered.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const DoorHandleRepair = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <GiDoorHandle size="80" className={styles.icon} />
        <div>
          <h3>Door Handle Repair</h3>
          <p>
            We're not limited to just locks! We also install and repair door
            handles.
          </p>
        </div>
      </article>
    </Fade>
  );
};

export const DoorRepair = () => {
  return (
    <Fade>
      <article className={styles.grid_item}>
        <BsFillDoorClosedFill size="80" className={styles.icon} />
        <div>
          <h3>Door Repair</h3>
          <p>
            Minor door damage? Our mobile service can (probably) fix it! Call
            and tell ust what needs fixing!
          </p>
        </div>
      </article>
    </Fade>
  );
};
