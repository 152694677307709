import React, { useEffect, useState } from "react";
import styles from "./logo.module.css";

// Icons
import { BsFillDoorClosedFill } from "react-icons/bs";

const Logo = ({ offset }) => {
  const [isSmall, setIsSmall] = useState(null);

  useEffect(() => {
    if (offset < 20) {
      setIsSmall(true);
    } else setIsSmall(false);
  }, [offset]);

  return (
    <>
      <div className={styles.logo_container}>
        <div className={styles.icon_container}>
          <BsFillDoorClosedFill
            className={isSmall ? styles.icon : styles.icon_small}
          />
        </div>
        <div
          className={
            isSmall ? styles.text_container : styles.text_container_small
          }
        >
          <h3>DLS</h3>
          <span>Doors, Locks & Services</span>
        </div>
      </div>
    </>
  );
};

export default Logo;
